import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Preloader } from "components";
import BasicModal from "../BasicModal";
import LevelLimitsInfoModal from "../LevelLimitsInfoModal";
import MainWithdrawStep from "./MainWithdrawStep";
import ConfirmWithdrawStep from "./ConfirmWithdrawStep";
import SuccessWithdrawStep from "./SuccessWithdrawStep";
import { SelectItemProps } from "components/Inputs/types";
import { CurrenciesStore, GlobalStore, UserStore } from "stores";
import {
  withdraw,
  // transfer
} from "api/wallet";
import { getUserStatistics } from "api/user";
import { foxpayWithdraw } from "api/foxpay";
import countries from "helpers/countries";
import { streetInputValidator, nameInputValidator } from "helpers/regex";
import type { WithdrawLimitInfo } from "components/Modals/LevelLimitsInfoModal";
import type {
  WithdrawReqInterface,
  FoxpayWithdrawReq,
  // TransferReqInterface,
} from "api/apiTypes";
import type {
  ModalStepsType,
  AvailableTradeOptions,
  PaymentVariantsProps,
} from "helpers/types";

interface InitialErrors {
  beneficiaryName?: string;
  beneficiaryCountryCode?: string;
  beneficiaryCity?: string;
  beneficiaryAddress?: string;
  beneficiaryPostCode?: string;
  beneficiaryIban?: string;
  paymentReference?: string;
}
const requiredField = "REQUIRED_FIELD";
export const bankWithdraw = "Bank Withdraw";
export const transferName = "Transfer";

interface WithdrawLimitStatisticsInterface {
  dailyWithdrawLimit: number;
  monthlyWithdrawLimit: number;
  yearlyWithdrawLimit: number;
}
interface AwailabeWithdrawInterface {
  dailyWithdraw: number;
  monthlyWithdraw: number;
  yearlyWithdraw: number;
}

interface WithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialVariant: AvailableTradeOptions;
  isFiat?: boolean;
}
const WithdrawModal: FC<WithdrawModalProps> = ({
  isOpen,
  onClose,
  initialVariant,
  isFiat = false,
}) => {
  const { t } = useTranslation();
  const { currencies, prices } = CurrenciesStore;
  const {
    statistics,
    feesAndLimits,
    user: { level },
  } = UserStore;
  const [step, setStep] = useState<ModalStepsType>("main");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [skipConfirm, setSkipConfirm] = useState<boolean>(false);
  const [limitInfo, setLimitInfo] = useState<WithdrawLimitInfo>({
    level: "DAILY",
    limit: 0,
    availableAmout: 0,
  });
  const [isLimitsModalOpen, setIsLimitsModalOpen] = useState<boolean>(false);
  const [currencyValue, setCurrencyValue] = useState<string>("");
  const [currency, setCurrency] = useState<AvailableTradeOptions | null>(null);
  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  const [withdrawAddress, setWithdrawAddress] = useState<string>("");
  const [withdrawMemo, setWithdrawMemo] = useState<string>("");
  const [twoFACode, setTwoFACode] = useState<string>("");
  const [receiverEmail, setReceiverEmail] = useState<string>("");
  const [transferNote, setTransferNote] = useState<string>("");
  const [paymentType, setPaymentType] = useState<
    PaymentVariantsProps | undefined
  >(undefined);

  const { values, errors, handleSubmit, handleChange, resetForm } = useFormik({
    initialValues: {
      beneficiaryName: "",
      beneficiaryCountryCode: "",
      beneficiaryCity: "",
      beneficiaryAddress: "",
      beneficiaryPostCode: "",
      beneficiaryIban: "",
      paymentReference: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);

      const personalData: FoxpayWithdrawReq = {
        beneficiaryName: values.beneficiaryName.replace(/\s+/g, " "),
        beneficiaryCountryCode: countries.find(
          ({ name }) => name === values.beneficiaryCountryCode
        )!.code,
        beneficiaryCity: values.beneficiaryCity.replace(/\s+/g, " "),
        beneficiaryAddress: values.beneficiaryAddress.replace(/\s+/g, " "),
        beneficiaryPostCode: values.beneficiaryPostCode.replace(/\s+/g, " "),
        beneficiaryIban: values.beneficiaryIban.replace(/\s+/g, " "),
        amount: currencyValue,
        paymentReference: values.paymentReference.replace(/\s+/g, " "),
        oneTimePassword: twoFACode,
        currencyName: currency!,
      };

      foxpayWithdraw(personalData)
        .then(() => {
          setStep("success");
          getUserStatistics();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    },
    validateOnChange: true,
    validate: (values) => {
      const errors: InitialErrors = {};
      if (!values.beneficiaryName) {
        errors.beneficiaryName = requiredField;
      }
      if (!values.beneficiaryCountryCode) {
        errors.beneficiaryCountryCode = requiredField;
      }
      if (!values.beneficiaryCity) {
        errors.beneficiaryCity = requiredField;
      }
      if (!values.beneficiaryAddress) {
        errors.beneficiaryAddress = requiredField;
      }
      if (!values.beneficiaryPostCode) {
        errors.beneficiaryPostCode = requiredField;
      }
      if (!values.beneficiaryIban) {
        errors.beneficiaryIban = requiredField;
      }
      if (!values.paymentReference) {
        errors.paymentReference = requiredField;
      }

      if (
        values.beneficiaryName &&
        !nameInputValidator.test(values.beneficiaryName)
      ) {
        errors.beneficiaryName = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (
        values.beneficiaryCity &&
        !nameInputValidator.test(values.beneficiaryCity)
      ) {
        errors.beneficiaryCity = `Only latin characters allowed (plus space, ' and - symbols)`;
      }
      if (
        values.beneficiaryAddress &&
        !streetInputValidator.test(values.beneficiaryAddress)
      ) {
        errors.beneficiaryAddress = `Only latin characters allowed (plus space, ', /, ",", and - symbols)`;
      }

      return errors;
    },
  });

  const limitsInfo = useMemo<WithdrawLimitStatisticsInterface>(() => {
    const zeroValues = {
      dailyWithdrawLimit: 0,
      monthlyWithdrawLimit: 0,
      yearlyWithdrawLimit: 0,
    };

    if (!feesAndLimits) {
      return zeroValues;
    }

    const withdrawLimit = feesAndLimits.limits.find(
      ({ walletOperationType }) => walletOperationType === "WITHDRAW"
    )?.value;

    if (!withdrawLimit) {
      return zeroValues;
    }
    const dailyWithdrawLimit =
      typeof withdrawLimit.day === "number" ? withdrawLimit.day : 0;
    const monthlyWithdrawLimit =
      typeof withdrawLimit.month === "number" ? withdrawLimit.month : 0;
    const yearlyWithdrawLimit =
      typeof withdrawLimit.year === "number" ? withdrawLimit.year : 0;

    const currentFeeLevelValues = {
      dailyWithdrawLimit,
      monthlyWithdrawLimit,
      yearlyWithdrawLimit,
    };

    return currentFeeLevelValues;
  }, [feesAndLimits]);

  const availableWithdraw = useMemo<
    AwailabeWithdrawInterface | undefined
  >(() => {
    if (!statistics?.withdrawal || !limitsInfo) {
      return undefined;
    }

    const dailyWithdraw =
      limitsInfo.dailyWithdrawLimit - statistics.withdrawal.last24h;
    const monthlyWithdraw =
      limitsInfo.monthlyWithdrawLimit - statistics.withdrawal.lastMonth;
    const yearlyWithdraw =
      limitsInfo.yearlyWithdrawLimit - statistics.withdrawal.lastYear;

    return {
      dailyWithdraw,
      monthlyWithdraw,
      yearlyWithdraw,
    };
  }, [statistics, limitsInfo]);

  const hasMemo = useMemo<boolean>(() => {
    if (chosenNetwork && paymentType?.useMemo) {
      return true;
    }
    return false;
  }, [chosenNetwork, paymentType]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === "main" && !skipConfirm) {
      setStep("confirm");
      return;
    }

    if (
      // chosenNetwork === bankWithdraw
      currency === "EUR"
    ) {
      handleSubmit();
      return;
    }

    // if (chosenNetwork === transferName) {
    //   const transferData: TransferReqInterface = {
    //     amount: Number(currencyValue),
    //     currencyName: currency!,
    //     toEmail: receiverEmail,
    //     note: transferNote,
    //     oneTimePassword: twoFACode,
    //   };

    //   setIsLoading(true);

    //   transfer(transferData)
    //     .then(() => {
    //       setStep("success");
    //       getUserStatistics();
    //     })
    //     .catch((err) => {
    //       if (err?.response?.data?.message) {
    //         GlobalStore.setError(err.response.data.message);
    //       }
    //     })
    //     .finally(() => setIsLoading(false));
    //   return;
    // }

    const currentPrices = prices?.[`${currency}/USD`];
    const mainCost = currentPrices?.close;

    if (
      availableWithdraw &&
      availableWithdraw.dailyWithdraw <
        Number(currencyValue) * (Number(mainCost) || 0)
    ) {
      setLimitInfo({
        level: "DAILY",
        limit: limitsInfo.dailyWithdrawLimit,
        availableAmout: availableWithdraw.dailyWithdraw,
      });
      setIsLimitsModalOpen(true);
      return;
    }
    if (
      availableWithdraw &&
      availableWithdraw!.monthlyWithdraw <
        Number(currencyValue) * (Number(mainCost) || 0)
    ) {
      setLimitInfo({
        level: "MONTHLY",
        limit: limitsInfo.monthlyWithdrawLimit,
        availableAmout: availableWithdraw.monthlyWithdraw,
      });
      setIsLimitsModalOpen(true);
      return;
    }
    if (
      availableWithdraw &&
      availableWithdraw!.yearlyWithdraw <
        Number(currencyValue) * (Number(mainCost) || 0)
    ) {
      setLimitInfo({
        level: "YEARLY",
        limit: limitsInfo.yearlyWithdrawLimit,
        availableAmout: availableWithdraw.yearlyWithdraw,
      });
      setIsLimitsModalOpen(true);
      return;
    }

    const withdrawData: WithdrawReqInterface = {
      amount: Number(currencyValue),
      currencyName: currency!,
      networkCode: chosenNetwork,
      toAddress: withdrawAddress.replaceAll(" ", ""),
      oneTimePassword: twoFACode,
    };

    if (hasMemo && withdrawMemo) {
      withdrawData.toMemo = withdrawMemo;
    }

    setIsLoading(true);

    withdraw(withdrawData)
      .then(() => {
        setStep("success");
        getUserStatistics();
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          GlobalStore.setError(err.response.data.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isOpen) {
      setCurrency(initialVariant);
      setPaymentType(currencies?.find(({ name }) => name === initialVariant)!);
    }
  }, [initialVariant, isFiat, currencies, isOpen]);

  const closeModal = () => {
    if (isLoading) {
      return;
    }
    onClose();
  };

  const resetState = useCallback(() => {
    setStep("main");
    setCurrencyValue("");
    setChosenNetwork("");
    setWithdrawAddress("");
    setWithdrawMemo("");
    setTwoFACode("");
    resetForm();
    setCurrency(null);
    setPaymentType(undefined);
  }, [resetForm]);

  const fixedFees = useMemo<number>(
    () =>
      chosenNetwork === transferName
        ? 0
        : paymentType?.withdrawFee
        ? Number(paymentType.withdrawFee)
        : 0,
    [paymentType, chosenNetwork]
  );

  const percentFees = useMemo<number>(() => {
    const currentPercent = feesAndLimits?.fees.find(
      ({ walletOperationType }) =>
        walletOperationType === "BANK_TRANSFER_WITHDRAW"
    )?.value?.feePercent;

    const feesFromValue = Number(currencyValue) * (Number(currentPercent) || 0);
    return feesFromValue || 0;
  }, [feesAndLimits, currencyValue]);

  const fees = useMemo<number>(
    () =>
      chosenNetwork === transferName
        ? 0
        : chosenNetwork === bankWithdraw
        ? fixedFees + percentFees
        : fixedFees,
    [fixedFees, percentFees, chosenNetwork]
  );

  const networkOptions = useMemo<Array<SelectItemProps> | undefined>(() => {
    if (currencies && paymentType && paymentType.name === "EUR") {
      return;
      // [
      //   {
      //     value: bankWithdraw,
      //     label: bankWithdraw,
      //   },
      //   // {
      //   //   value: transferName,
      //   //   label: "FNT Internal Transfer",
      //   // },
      // ];
    }
    if (currencies && paymentType) {
      const commonOptions = currencies
        .filter(({ name }) => name === paymentType.name)
        .map((el) => ({
          value: el.networkCode,
          label: el.networkName,
        }));

      return commonOptions;

      // return [
      //   ...commonOptions,
      //   {
      //     value: transferName,
      //     label: "FNT Internal Transfer",
      //   },
      // ];
    }
    return undefined;
  }, [currencies, paymentType]);

  useEffect(() => {
    if (
      isOpen &&
      !chosenNetwork &&
      paymentType?.networkCode &&
      currencies?.filter((el) => el.name === paymentType?.name)?.length === 1
    ) {
      setChosenNetwork(paymentType?.networkCode);
    }
  }, [paymentType, chosenNetwork, currencies, isOpen]);

  // useEffect(() => {
  //   if (chosenNetwork !== transferName && (receiverEmail || transferNote)) {
  //     setReceiverEmail("");
  //     setTransferNote("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chosenNetwork]);

  return (
    <BasicModal
      isOpen={isOpen}
      onExited={resetState}
      onClose={closeModal}
      title={t("WITHDRAW")}
    >
      {isLoading && <Preloader isStatic />}

      {step === "main" && (
        <MainWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          currencyValue={currencyValue}
          currency={currency}
          setCurrencyValue={setCurrencyValue}
          setCurrency={setCurrency}
          onClose={closeModal}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          fees={fees}
          fixedFees={fixedFees}
          percentFees={percentFees}
          currencies={currencies}
          networkOptions={networkOptions}
          chosenNetwork={chosenNetwork}
          setChosenNetwork={setChosenNetwork}
          setWithdrawAddress={setWithdrawAddress}
          withdrawAddress={withdrawAddress}
          setIsLoading={setIsLoading}
          setTwoFACode={setTwoFACode}
          withdrawMemo={withdrawMemo}
          hasMemo={hasMemo}
          setWithdrawMemo={setWithdrawMemo}
          twoFACode={twoFACode}
          isLoading={isLoading}
          values={values}
          errors={errors}
          handleChange={handleChange}
          resetForm={resetForm}
          receiverEmail={receiverEmail}
          setReceiverEmail={setReceiverEmail}
          transferNote={transferNote}
          setTransferNote={setTransferNote}
        />
      )}
      {step === "confirm" && (
        <ConfirmWithdrawStep
          onSubmit={onSubmit}
          skipConfirm={skipConfirm}
          setSkipConfirm={setSkipConfirm}
          currencyValue={currencyValue}
          currency={currency}
          onBack={() => setStep("main")}
          onClose={closeModal}
          fees={fees}
          fixedFees={fixedFees}
          percentFees={percentFees}
          currencies={currencies}
          setTwoFACode={setTwoFACode}
          twoFACode={twoFACode}
          isLoading={isLoading}
          values={values}
          chosenNetwork={chosenNetwork}
          receiverEmail={receiverEmail}
        />
      )}
      {step === "success" && (
        <SuccessWithdrawStep
          currencyValue={currencyValue}
          currency={currency}
          onClose={closeModal}
          fees={fees}
          fixedFees={fixedFees}
          percentFees={percentFees}
          currencies={currencies}
          values={values}
          chosenNetwork={chosenNetwork}
        />
      )}
      <LevelLimitsInfoModal
        onClose={() => setIsLimitsModalOpen(false)}
        isOpen={isLimitsModalOpen}
        userLevel={level}
        info={limitInfo}
      />
    </BasicModal>
  );
};

export default observer(WithdrawModal);
