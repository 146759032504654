import routesPaths from "routes/routesPaths";

import { ReactComponent as TradeIcon } from "assets/icons/swap.svg";
// import { ReactComponent as EarningIcon } from "assets/icons/earning.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as HourglassIcon } from "assets/icons/hourglass.svg";
// import { ReactComponent as LineChartUpIcon } from "assets/icons/line-chart-up.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";
// import { ReactComponent as GameIcon } from "assets/icons/game.svg";

const authRoutes = [
  {
    key: "trade",
    path: routesPaths.trade,
    label: "TRADE",
    icon: <TradeIcon />,
  },
  // {
  //   key: "earning",
  //   path: routesPaths.earning,
  //   label: process.env.REACT_APP_ENVIRONMENT === "dev" ? "EARNING" : "STAKING",
  //   icon: <EarningIcon />,
  // },
  {
    key: "wallet",
    path: routesPaths.wallet,
    label: "WALLET",
    icon: <WalletIcon />,
  },
  {
    key: "history",
    path: routesPaths.history,
    label: "HISTORY",
    icon: <HourglassIcon />,
  },
  // {
  //   key: "futures",
  //   path: routesPaths.futures,
  //   label: "FUTURES",
  //   icon: <LineChartUpIcon />,
  // },
  {
    key: "crypto-cards",
    path: routesPaths.cryptoCards,
    label: "CRYPTO_CARDS",
    icon: <CardIcon />,
  },
  // {
  //   key: "games",
  //   path: routesPaths.games,
  //   label: "GAMES",
  //   icon: <GameIcon />,
  // },
  // {
  //   key: "gift-cards",
  //   path: routesPaths.giftCards,
  //   label: "GIFT_CARDS",
  //   icon: <CardIcon />,
  // },
];

export default authRoutes;
