import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors, currencyIcons } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText } from "components";
import WithdrawInfoRows from "./WithdrawInfoRows";
import { normolizeDecimalsWithNoCode } from "helpers/funcs";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  StatusInfo,
  InfoColumn,
  StatusInfoLine,
} from "../styled";
// import { transferName } from "./index";
import type { AvailableTradeOptions, CurrencyInterface } from "helpers/types";

import stakeImg from "assets/images/stake_success.jpg";
import { ReactComponent as CheckIcon } from "assets/icons/check-circle.svg";
import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface SuccessStakeStepProps {
  onClose: () => void;
  currencyValue: string;
  currency: AvailableTradeOptions | null;
  fees: number;
  fixedFees: number;
  percentFees: number;
  currencies: CurrencyInterface[] | null;
  values: { [field: string]: any };
  chosenNetwork: string;
}

const SuccessStakeStep: FC<SuccessStakeStepProps> = ({
  onClose,
  currencyValue,
  currency,
  fees,
  fixedFees,
  percentFees,
  currencies,
  values,
  chosenNetwork,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <img src={stakeImg} alt="decorative" />
          <StatusIconContainer>
            <CheckIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <StatusInfo>
          <InfoColumn>
            <AppText
              fontSize={20}
              fontWeight={600}
              color={colors.gray_600}
              lineHeight={30}
            >
              {currency}
            </AppText>
            <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
              {t(
                // chosenNetwork === transferName ? "TRANSFER" :
                "WITHDRAW"
              )}{" "}
              {t("CONFIRMED")}
            </AppText>
            <StatusInfoLine>
              {currencyIcons[currency!] ? currencyIcons[currency!] : <NAIcon />}
              <AppText
                fontSize={20}
                fontWeight={600}
                color={colors.gray_700}
                lineHeight={30}
              >
                {normolizeDecimalsWithNoCode(
                  Number(currencyValue),
                  currency || "USDT",
                  currencies
                )}
              </AppText>
              {currency !== "EUR" && (
                <AppText
                  isSpan
                  fontSize={20}
                  fontWeight={600}
                  color={colors.gray_400}
                  lineHeight={30}
                >
                  {" "}
                  {currency}
                </AppText>
              )}
            </StatusInfoLine>
          </InfoColumn>

          <WithdrawInfoRows
            currencyValue={currencyValue}
            currency={currency}
            fees={fees}
            fixedFees={fixedFees}
            percentFees={percentFees}
            values={values}
            chosenNetwork={chosenNetwork}
          />

          <ModalButton onClick={onClose}>{t("DONE")}</ModalButton>
        </StatusInfo>
      </StatusContent>
    </Fade>
  );
};

export default SuccessStakeStep;
